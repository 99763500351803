import React, {useEffect, useState} from "react";
import {Box, Card, CardContent, CircularProgress, InputAdornment, Link, Stack, Typography} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridRenderCellParams,
    GridToolbarQuickFilter
} from "@mui/x-data-grid";
import {AuthenticationContext} from "../AuthenticationContext";
import {GridRowParams} from "@mui/x-data-grid/models/params/gridRowParams";
import {GridActionsColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {Voicemail} from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';
import AppLayout from "../layout/AppLayout";

interface ReadingItem {
    library: string,
    id: string,
    title: string,
    countSentences: number,
    countWords: number,
    excerpt: string
}

interface Library {
    id: string,
    description: string,
    readings: ReadingItem[]
}

interface LibrariesResponse {
    libraries: Library[]
}

const ReadingsList: React.FC = () => {
    const userContext = React.useContext(AuthenticationContext)


    const [libraries, setLibraries] = useState<Library[]>([])
    const [selectedLibrary, setSelectedLibrary] = useState<Library | undefined>(undefined)
    const navigate = useNavigate()
    const [showLevelSelector, setShowLevelSelector] = useState(false)

    const levelSelector = <Box sx={{
        padding: 5,
        borderRadius: 5,
        backgroundColor: "white",
        width: "100%",
        display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px,max-content))',
        gap: 2,
        alignContent: "stretch",
        overflow: "auto"
    }}>
        {
            libraries?.map((library) =>
                <Link underline={"hover"} onClick={() => {
                    setShowLevelSelector(false);
                    setSelectedLibrary(library)
                }} href={"#"}>
                    <Card elevation={3} sx={{
                        aspectRatio: 1.33,
                        backgroundImage: 'url("library-card-background.svg")',
                        backgroundSize: "cover",
                        borderRadius: 5,
                        padding: 0,
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex"
                    }}>
                        <CardContent>
                            <Typography sx={{
                                textTransform: "none",
                                color: "secondary.main",
                                fontSize: "1.5em",
                                fontFamily: "sans-serif",
                                textAlign: "center",
                                fontWeight: 600,
                            }}>{library?.description}</Typography>
                        </CardContent>
                    </Card>
                </Link>
            )}
    </Box>


    const quickSearchToolbar = () =>
        <Grid container spacing={3} sx={{
            justifyContent: "center",
            alignItems: "center",
        }}>
            <Grid size="grow">
                <GridToolbarQuickFilter variant={"standard"} placeholder="..." slotProps={{
                    input: {
                        startAdornment: (<InputAdornment position={"end"}><SearchIcon/></InputAdornment>),
                        sx: {
                            borderRadius: 5,
                        }
                    }
                }}/>
            </Grid>
            <Grid size={6}>
                <Link href={"#"} underline={"hover"} onClick={() => setShowLevelSelector(true)} sx={{
                    textTransform: "none",
                    color: "#CE142A",
                    fontSize: "2em",
                    fontFamily: "sans-serif",
                    textAlign: "center",
                    fontWeight: 900,
                    WebkitTextStrokeWidth: "1px",
                    WebkitTextStrokeColor: "#E26B1E",
                    display: "flex",
                    justifyContent: "center"
                }}>{selectedLibrary?.description}</Link>
            </Grid>
            <Grid size="grow">
            </Grid>
        </Grid>


    useEffect(() => {
        userContext.client().get<LibrariesResponse>(`readings/list`).then(({data}) => {
            setLibraries(data.libraries)
            setSelectedLibrary(data.libraries[0])
        })
    }, [userContext])

    const columns: () => (GridColDef | GridActionsColDef)[] = () => {
            const titleColumn: GridColDef = {
                field: 'title',
                headerName: 'Tytuł',
                flex: 1,
                minWidth: 150,
                renderCell: (params: GridRenderCellParams) => (
                    <Link color="primary" underline="hover" component={RouterLink}
                          to={`/reading/${selectedLibrary?.id}/${params.row.id}`}>{params.value}</Link>
                ),
            }
            const countSentencesColumn: GridColDef = {field: 'countSentences', headerName: 'Zdań'}
            const countWordsColumn = {field: 'countWords', headerName: 'Słów'}
            const excerptColumn = {field: 'excerpt', headerName: 'Przykład', flex: 5}
            if (userContext.user().roles.includes("therapist") || userContext.user().roles.includes("admin")) {
                const actionColumns: GridActionsColDef = {
                    field: 'actions',
                    type: 'actions',
                    width: 30,
                    getActions: (params: GridRowParams<ReadingItem>) => [
                        <GridActionsCellItem
                            icon={<Voicemail/>}
                            label="Nagraj sesję"
                            onClick={() => navigate(`/reading/${selectedLibrary}/${params.row.id}?record`)}
                        />
                    ]
                }
                return [titleColumn, actionColumns, countSentencesColumn, countWordsColumn, excerptColumn]
            } else return [titleColumn, countSentencesColumn, countWordsColumn, excerptColumn]
        }
    ;


    const readings = libraries.find((library) => library.id === selectedLibrary?.id)?.readings || []
    const content = showLevelSelector ? levelSelector : readings ?
        <DataGrid density='compact' rows={readings} columns={columns()}
                  disableRowSelectionOnClick
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  slots={{toolbar: quickSearchToolbar}}
                  sx={{
                      borderRadius: 5,
                      backgroundColor: "white",
                      height: "100%",
                      '& .MuiDataGrid-cell:focus': {
                          outline: 'none',
                      },
                  }}
                  initialState={{
                      sorting: {
                          sortModel: [{field: 'title', sort: 'asc'}],
                      },
                  }}
                  isCellEditable={() => false}
        /> :
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <CircularProgress/>
        </Box>


    return (
        <AppLayout showAppBar={true} appBarComponents={
            <Stack direction={"row"} flexGrow={1}>
                <Link
                    underline="hover"
                    component="button"
                    onClick={() => navigate("/sessions")}
                >
                    Historia
                </Link>
            </Stack>}>
            {content}
        </AppLayout>

    )
}

export default ReadingsList

import React, {useState} from "react";
import {initializeApp} from "firebase/app"
import {
    confirmPasswordReset,
    createUserWithEmailAndPassword,
    getAuth,
    GoogleAuthProvider,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup
} from "firebase/auth"
import "firebaseui/dist/firebaseui.css";
import {ApplicationUser} from "./AuthenticationContext";
import {Button, Divider, Paper, Stack, TextField, Typography} from "@mui/material";
import AppLayout from "./layout/AppLayout";
import GoogleIcon from '@mui/icons-material/Google';
import {useNavigate, useSearchParams} from "react-router-dom";
import {ResetPasswordRoute, SignUpRoute} from "./index";

const firebaseConfig = {
    apiKey: "AIzaSyCGyIkNY7Lsfv-lxnHVzXJ1Mh1jW-WQluE",
    authDomain: process.env.REACT_APP_AUTH_DOMAIN, //https://cloud.google.com/identity-platform/docs/web/redirect-best-practices#update-authdomain
};

interface Props {
    setUser: (user: ApplicationUser | null) => void
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const googleAuthProvider = new GoogleAuthProvider()
googleAuthProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
auth.languageCode = 'pl'

const decodeAuthError = (code: string) => ({
    "auth/invalid-email": "Wprowadź poprawny adres email",
    "auth/user-not-found": "Podany użytkownik nie istnieje",
    "auth/email-already-in-use": "Konto z podanym adresem email już istnieje",
    "auth/weak-password": "Zbyt proste hasło"
})[code]

const AuthDialog: React.FC<{ children: React.ReactNode }> = ({children}) =>
    <AppLayout showAppBar={false} centerVertically>
        <Paper elevation={4} sx={{
            borderRadius: 3,
            width: 1 / 3,
            padding: 2,
            marginX: 'auto',
            minWidth: '400px',
            marginTop: 3,
            display: "flex",
            flexDirection: "column"
        }}>
            {children}
        </Paper>
    </AppLayout>

const Message: React.FC<{ msg: string, onClose: () => void }> = ({msg, onClose}) =>
    <AuthDialog>
        <Typography variant="h6" align="center" fontFamily="Roboto" sx={{padding: 3}}>
            {msg}
        </Typography>
        <Stack alignItems={"flex-end"} spacing={2}>
            <Button className={"rounded"} variant={"contained"} size={"large"} fullWidth
                    onClick={onClose}>Zamknij</Button>
        </Stack>
    </AuthDialog>

export const SignUp: React.FC = () => {
    const [state, setState] = useState<keyof typeof states>("signup")

    const [password, setPassword] = useState<string | undefined>("")
    const [username, setUsername] = useState<string | undefined>()
    const [error, setError] = useState<string | undefined>()
    const navigate = useNavigate()


    const googleLogin = () =>
        signInWithPopup(auth, googleAuthProvider).then(() => setState("succeeded"))
            .catch((error) => {
                console.log(JSON.stringify(error))
                setError(decodeAuthError(error.code))
                setState("failed")
            })


    const signUpWithEmailAndPassword = (username: string | undefined, password: string | undefined) => () =>
        username && password && createUserWithEmailAndPassword(auth, username, password).then(() => setState("succeeded"))
            .catch((error) => {
                console.log(JSON.stringify(error))
                setError(decodeAuthError(error.code))
                setState("failed")
            })

    const states = {
        signup: <AuthDialog>
            <Typography variant="h6" align="center" fontFamily="Roboto" sx={{padding: 3}}>
                Utwórz nowe konto
            </Typography>
            <form><Stack spacing={2}>
                <TextField label="Email" autoComplete={"username"} variant="filled" fullWidth required
                           onChange={event => setUsername(event.target.value)}
                           value={username} type="email"/>
                <Stack alignItems={"flex-end"} spacing={0}>

                    <TextField label="Hasło" type="password" autoComplete={"current-password"}
                               variant="filled" fullWidth required
                               onChange={event => setPassword(event.target.value)}
                               value={password}/>

                </Stack>

                <Button disabled={!username || !password} className={"rounded"} variant={"contained"} size={"large"}
                        fullWidth
                        onClick={signUpWithEmailAndPassword(username, password)}>Utwórz</Button>
                <Divider/>
                <Button className={"rounded"} variant={"contained"} size={"large"} fullWidth
                        onClick={googleLogin} startIcon={<GoogleIcon/>}>Zaloguj przez google</Button>
            </Stack>
            </form>
        </AuthDialog>,
        succeeded: <Message
            msg={`Konto zostało utworzone`}
            onClose={() => navigate("/")}/>,
        failed: <Message msg={error || "Nie udało się utworzyć konta"} onClose={() => setState("signup")}/>
    }
    return (states[state])

}

export const ResetPassword: React.FC = () => {
    const [state, setState] = useState<keyof typeof states>("resetPassword")
    const navigate = useNavigate()
    const [error, setError] = useState<string | undefined>()
    const [username, setUsername] = useState<string | undefined>()

    const sendPasswordResetLink = () =>
        username && sendPasswordResetEmail(auth, username)
            .then(() => setState("succeeded"))
            .catch((error) => {
                console.log(JSON.stringify(error))
                setError(decodeAuthError(error.code))
                setState("failed")
            })

    const states = {
        resetPassword: <AuthDialog>
            <Typography variant="h6" align="center" fontFamily="Roboto" sx={{padding: 3}}>
                Wprowadź adres email konta
            </Typography>
            <Stack spacing={5}>
                <form><Stack spacing={2}>
                    <TextField label="Email" autoComplete={"username"} variant="filled" fullWidth required
                               onChange={event => setUsername(event.target.value)}
                               value={username} type="email"/>
                </Stack>
                </form>
                <Stack alignItems={"flex-end"} spacing={2}>
                    <Button disabled={!username} className={"rounded"} variant={"contained"} size={"large"} fullWidth
                            onClick={sendPasswordResetLink}>Wyślij link</Button>
                </Stack>
            </Stack>
        </AuthDialog>,
        succeeded: <Message
            msg={`Na podany adres email została wysłana wiadomość z linkiem do zmiany hasła. Sprawdź skrzynkę pocztową`}
            onClose={() => navigate("/")}/>,
        failed: <Message msg={error || "Nie udało się wysłać wiadomości"} onClose={() => setState("resetPassword")}/>
    }
    return (states[state])
}

export const ChangePassword: React.FC = () => {
    const [state, setState] = useState<keyof typeof states>("changePassword")
    const navigate = useNavigate()
    const [newPassword, setNewPassword] = useState<string>("")
    const [searchParams] = useSearchParams()
    const mode = searchParams.get("mode")
    const oobCode = searchParams.get("oobCode")

    if (mode !== "resetPassword" || !oobCode) return (
        <Message msg={"Niepoprawny link do zmiany hasła, spróbuj jeszcze raz"} onClose={() => navigate("/")}/>
    )
    const changePassword = () =>
        confirmPasswordReset(auth, oobCode, newPassword)
            .then(() => setState("succeeded"))
            .catch((error) => setState("failed"))

    const states = {
        changePassword: <AuthDialog>
            <Typography variant="h6" align="center" fontFamily="Roboto" sx={{padding: 3}}>
                Nowe hasło
            </Typography>
            <Stack spacing={5}>
                <form><Stack spacing={2}>
                    <TextField label="Hasło" type="password" autoComplete={"new-password"}
                               variant="filled" fullWidth
                               onChange={event => setNewPassword(event.target.value)}
                               value={newPassword}/>

                </Stack>
                </form>
                <Stack alignItems={"flex-end"} spacing={2}>
                    <Button className={"rounded"} variant={"contained"} size={"large"} fullWidth
                            onClick={changePassword}>Zmień</Button>
                </Stack>
            </Stack>
        </AuthDialog>,
        succeeded: <Message msg={"Hasło zostało zmienione"} onClose={() => navigate("/")}/>,
        failed: <Message msg={"Nie udało się zmienić hasła, spróbuj jeszcze raz"} onClose={() => navigate("/")}/>
    }
    return (states[state])
}
//reference https://firebase.google.com/docs/reference/js/auth
export const Login: React.FC<Props> = (props) => {
    const [password, setPassword] = useState<string | undefined>("")
    const [username, setUsername] = useState<string | undefined>()
    const navigate = useNavigate()


    const googleLogin = () =>
        signInWithPopup(auth, googleAuthProvider)

    const passwordLogin = (username: string | undefined, password: string | undefined) => () =>
        username && password && signInWithEmailAndPassword(auth, username, password)

    return (
        <AuthDialog>
            <Typography variant="h6" align="center" fontFamily="Roboto" sx={{padding: 3}}>
                Zaloguj się
            </Typography>
            <form>
                <Stack spacing={2}>
                    <TextField label="Email" autoComplete={"username"} variant="filled" fullWidth required
                               onChange={event => setUsername(event.target.value)}
                               value={username} type="email"/>
                    <TextField label="Hasło" type="password" autoComplete={"current-password"}
                               variant="filled" fullWidth required
                               onChange={event => setPassword(event.target.value)}
                               value={password}/>
                    <Button disabled={!username || !password} className={"rounded"} variant={"contained"} size={"large"}
                            fullWidth
                            onClick={passwordLogin(username, password)}>Zaloguj</Button>
                    <Button size={"small"}
                            onClick={() => navigate(ResetPasswordRoute)}>Nie pamiętam hasła</Button>
                </Stack>
            </form>

            <Stack spacing={2}>
                <Divider/>
                <Button className={"rounded"} variant={"contained"} size={"large"} fullWidth
                        onClick={googleLogin} startIcon={<GoogleIcon/>}>Zaloguj przez google</Button>
                <Divider/>
                <Button size={"small"} onClick={() => navigate(SignUpRoute)}>Nie mam jeszcze konta</Button>

            </Stack>
            {/*</Stack>*/}
        </AuthDialog>
    )

}
import axios, {AxiosRequestConfig} from "axios";
import React, {ChangeEvent, useEffect, useState} from "react";
import {AuthenticationContext} from "../AuthenticationContext";
import {Box, CircularProgress, MenuItem, TextField} from "@mui/material";
import * as O from "fp-ts/Option";
import {none, Option, some} from "fp-ts/Option";
import {pipe} from 'fp-ts/function'

const context = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BACKEND}/api/v1/trainees`

const securedRequest = (apiKey: string, config: AxiosRequestConfig) =>
    axios.request({
        headers: {
            Authorization: `Bearer ${apiKey}`,
        },
        ...config,
    });

export interface Trainee {
    id: string,
    name: string,
}

interface SelectTraineeProps {
    onTrainee: (trainee: Trainee) => void
}

export const SelectTrainee: React.FC<SelectTraineeProps> = (props) => {
    const user = React.useContext(AuthenticationContext).user()

    const [trainees, setTrainees] = useState<Option<Map<String, Trainee>>>(none)

    const traineeChanged = (trainees: Map<String, Trainee>, event: ChangeEvent<HTMLInputElement>) => {
        const trainee = trainees.get(event.target.value)
        if (trainee) props.onTrainee(trainee)
    }

    useEffect(() => {
        user?.getIdToken().then(token =>
            securedRequest(token, {
                method: "GET",
                url: `${context}/list`
            }).then(({data}) => {
                setTrainees(some(new Map(data.trainees.map((i: any) => [i.id, i]))))
                if (data.trainees.length === 1) {
                    props.onTrainee(data.trainees[0])
                }
            }))
    }, [])

    return pipe(
        trainees,
        O.fold(
            () => <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <CircularProgress/>
            </Box>,
            trainees => <TextField select label="Uczeń" value="" onChange={(event: ChangeEvent<HTMLInputElement>) => traineeChanged(trainees, event)}>
                {
                    [...trainees.values()].map((trainee, idx) => <MenuItem key={idx} value={trainee.id}>{trainee.name}</MenuItem>)
                }
            </TextField>
        ))
}
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

import Medals from "./Medals";

import {
    Box,
    Button,
    ButtonProps,
    LinearProgress,
    Stack,
    ToggleButton as MuiToggleButton,
    Toolbar,
    Tooltip,
    Typography,
    useMediaQuery
} from "@mui/material";
import {grey} from '@mui/material/colors';
import Hearing from "@mui/icons-material/Hearing";
import {AuthenticationContext} from "../AuthenticationContext";
import * as Messaging from "./Messaging";
import {
    InputMessage,
    ReadingStats,
    ReadingUpdate,
    RecognitionProfile,
    SessionConfig,
    SessionConfigUpdate,
    SessionInfo,
    TimeSync,
    UnreachableCaseError
} from "./Messaging";
import {defaultMicrophone, MicCapture, MicrophoneDevice} from "./MicCapture";
import {TraineeContext} from "../trainee/TraineeContext";
import {SessionEdit} from "../trainee/SessionEdit";
import {SessionRestartDialog} from "./SessionRestartDialog";
import {SessionConfigDialog} from "../trainee/SessionConfigDialog";
import {ReadingContent} from "./ReadingContent";
import {Subject, throttleTime} from "rxjs";
import SignalCellular1BarRoundedIcon from '@mui/icons-material/SignalCellular1BarRounded';
import SignalCellular2BarRoundedIcon from '@mui/icons-material/SignalCellular2BarRounded';
import SignalCellular3BarRoundedIcon from '@mui/icons-material/SignalCellular3BarRounded';
import SignalCellular4BarRoundedIcon from '@mui/icons-material/SignalCellular4BarRounded';
import SignalWifi1BarRoundedIcon from '@mui/icons-material/SignalWifi1BarRounded';
import SignalWifi2BarRoundedIcon from '@mui/icons-material/SignalWifi2BarRounded';
import SignalWifi3BarRoundedIcon from '@mui/icons-material/SignalWifi3BarRounded';
import SignalWifi4BarRoundedIcon from '@mui/icons-material/SignalWifi4BarRounded';
import AppLayout from "../layout/AppLayout";
import {styled} from "@mui/material/styles";
import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
    KeyboardDoubleArrowLeft,
    KeyboardDoubleArrowRight,
    Pause,
    PlayArrow,
    Stop
} from "@mui/icons-material";
import {ToggleButtonProps} from "@mui/material/ToggleButton/ToggleButton";

const play = (buffer: Blob) => {
    let ctx = new AudioContext()
    buffer.arrayBuffer()
        .then(data => ctx.decodeAudioData(data))
        .then(audio => {
            const src = ctx.createBufferSource()
            src.buffer = audio
            src.connect(ctx.destination)
            src.onended = () => ctx.close()
            src.start()
        })

}

const readingPath = "/api/v1/readings"

export interface SessionConfigVersioned<T = RecognitionProfile> {
    version: number,
    sessionConfig: SessionConfig<T>
}

type ConnectionControl = "connect" | "disconnect" | "failure"
const connectWs = (user: string,
                   token: string,
                   library: string | undefined,
                   resource: string | undefined,
                   traineeId: string,
                   sessionId: string | undefined,
                   record: boolean,
                   onMessage: (messsage: ReadingUpdate) => void,
                   onStats: (stats: ReadingStats) => void,
                   onSessionConfig: (sessionConfig: SessionConfigVersioned) => void,
                   onClose: () => void,
                   onComplete: () => void,
                   onSessionInfo: (sessionInfo: SessionInfo) => void,
                   onConnectionQuality: (connectionQuality: number) => void) => {
    const protocol = process.env.REACT_APP_PROTOCOL === "http" ? "ws" : "wss"
    const sessionIdParam = sessionId ? `&session=${sessionId}` : ""
    const recordParam = record ? `&record=true` : ""
    let connectionQuality = 10
    return new Promise<WebSocket>((resolve, reject) => {
        let conn = new WebSocket(`${protocol}://${process.env.REACT_APP_BACKEND}${readingPath}/read/${library}/${resource}?user=${user}&trainee=${traineeId}${sessionIdParam}${recordParam}`)

        conn.onopen = (d) => {
            conn.send(token)
            resolve(conn)
        }
        conn.onerror = error => {
            console.log(error)
            reject(error)
        }
        conn.onmessage = event => {
            if (typeof event.data === "string") {
                const message = JSON.parse(event.data) as InputMessage
                const messageType = message.type
                switch (messageType) {
                    case "complete":
                        onComplete()
                        break
                    case "reading-update":
                        onMessage(message)
                        break
                    case "latency-check":
                        const latency = Date.now() - message.timestamp
                        conn.send(JSON.stringify(TimeSync(latency)))
                        connectionQuality = 0.9 * connectionQuality + 0.1 * latency;
                        onConnectionQuality(connectionQuality)
                        break
                    case "info":
                        console.log(message.message);
                        break
                    case "session-info":
                        onSessionInfo(message)
                        break
                    case "stats":
                        onStats(message)
                        break
                    case "session-config-announcement":
                        onSessionConfig({version: message.version, sessionConfig: message.config})
                        break;
                    default:
                        throw new UnreachableCaseError(messageType)
                }
            } else play(event.data)
        }
        conn.onclose = event => {
            console.log("Closing socket...", event)
            onClose()
        }
    })
}

interface ReadingProps {
    library: string
    readingTitle: string
    record: string
}

interface MessageSender {
    sendMessage(message: Messaging.OutputMessage): void

    sendData(data: ArrayBufferLike): void

    close(): void
}

let createStyledComponent = styled(Button);
const ControlButton = createStyledComponent<ButtonProps>(({theme}) =>
    ({
        height: "36px",
        minWidth:"40px",
        color: theme.palette.text.primary,
        border: "solid",
        borderWidth: "2px",
        borderRadius: "5px",
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.primary.main,
        textTransform: "none",
        padding: 2,
        paddingRight: 4,

    })
)
const ToggleButton = styled(MuiToggleButton)<ToggleButtonProps>(({theme}) =>
    ({
        height: "36px",
        color: theme.palette.text.primary,
        border: "solid",
        borderWidth: "2px",
        borderRadius: "5px",
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.primary.main,
        textTransform: "none",
        padding: 2,
        paddingRight: 4,
        '&.MuiToggleButton-root.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
        }
    })
)

const Reading: React.FC = () => {
    //context
    const big = useMediaQuery((theme) => theme.breakpoints.up("md"));
    const userContext = React.useContext(AuthenticationContext)
    const traineeContext = React.useContext(TraineeContext)
    const cRef: React.RefObject<HTMLSpanElement | null> = React.createRef<HTMLSpanElement>()

    //url params
    const params = useParams<keyof ReadingProps>()
    const [searchParams] = useSearchParams()
    const record = searchParams.has("record")
    //navigation
    const navigate = useNavigate()

    //state
    //controls webscoket connection:
    // "connect" - should initiate connection
    // "disconnect" - intended disconnection
    // "failure" - unintended disconnection (error, or server disconnected)
    const [connect, setConnect] = useState<ConnectionControl>("connect")
    //set to true when the signal "reading complete" has been received
    const [complete, setComplete] = useState(false)
    //keeps current session id received from server
    const sessionInfo = useRef<SessionInfo>(undefined)
    //current reading content
    const [reading, setReading] = useState<ReadingUpdate>()
    //current web socket connection
    const [ws, setWs] = useState<MessageSender>()
    //current audio sink (sends to current websocket connection)
    const [micOutput, setMicOutput] = useState<(data: any) => void>()
    //current stats
    const [stats, setStats] = useState<ReadingStats>()
    //current audio source
    const [microphone, setMicrophone] = useState<MicrophoneDevice>(defaultMicrophone)
    //controls showing questions dialog
    const [showQuestions, setShowQuestions] = useState(false)
    //controls showing setting dialog
    const [settingOpen, setSettingOpen] = useState(false)

    const [sessionConfig, setSessionConfig] = useState<SessionConfigVersioned>()

    const [connectionQuality, setConnectionQuality] = useState(0)


    const [volume, setVolume] = useState(0);
    const onVolume = useMemo(() => {
        const subject = new Subject<number>()
        subject.pipe(throttleTime(200)).subscribe(setVolume)
        return subject
    }, [])
    const onComplete = () => {
        console.log("Completing...")
        setConnect("disconnect")
        setComplete(true)
        console.log("Completed!")
    }
    const sentenceProgress = () => {
        if (stats?.sentenceTime && stats.sentenceLength) {
            return stats.sentenceTime * 100 / stats.sentenceLength
        } else return 0
    }
    const totalTime = () => {
        if (stats?.scriptTime) {
            let minutes = Math.floor(stats.scriptTime / 60)
            let seconds = stats.scriptTime % 60
            return minutes + (seconds > 9 ? ":" : ":0") + seconds
        } else return "00:00"
    }

    const keyboardShortcutsHandler = useCallback<(sender: MessageSender, evt: KeyboardEvent) => void>((sender, event) => {
        switch (event.code) {
            case 'Space':
            case 'ArrowRight':
            case 'ArrowDown':
                sender.sendMessage(Messaging.Forward)
                break;
            case 'Backspace':
            case 'ArrowLeft':
            case 'ArrowUp':
                sender.sendMessage(Messaging.Rewind)
        }
    }, [])

    useEffect(() => {
        if (connect !== "connect") return //will return but first will run the previous finalizer
        const user = userContext.user()
        const trainee = traineeContext.trainee()
        const connPromise = user.getIdToken().then(token =>
            connectWs(user.email, token, params.library, params.readingTitle, trainee.id, sessionInfo.current?.sessionId, record,
                setReading, setStats, setSessionConfig, () => {
                    setConnect((prev) => prev === "connect" ? "failure" : "disconnect")
                },
                onComplete, (value) => sessionInfo.current = value, setConnectionQuality
            )).then(conn => {
            const sender: MessageSender = {
                close(): void {
                    console.log("Closing sender")
                    conn.close()
                },
                sendData(data: ArrayBufferLike): void {
                    conn.send(data)
                },
                sendMessage(message: Messaging.OutputMessage): void {
                    conn.send(JSON.stringify(message))
                }
            }
            const newMicOutput = (data: any) => sender.sendData(data)

            setWs(sender)
            setMicOutput((_: any) => newMicOutput)
            const keyListener: (event: KeyboardEvent) => void = event => {
                // event.stopPropagation()
                event.preventDefault()
                keyboardShortcutsHandler(sender, event)
            }
            document.addEventListener("keydown", keyListener, {capture: true})
            return {conn, keyListener}
        })

        return () => {
            console.log("Executing shutdown...")
            connPromise.then(({conn, keyListener}) => {
                conn.close()
                document.removeEventListener("keydown", keyListener, {capture: true})
                setWs(undefined)
                setMicOutput(undefined)
            })
        }

    }, [connect, userContext, traineeContext, params.readingTitle, params.library, keyboardShortcutsHandler, sessionInfo, record])

    useEffect(() => cRef.current?.scrollIntoView({behavior: 'smooth', block: 'center'}))
    const closeSettings = useCallback(() => setSettingOpen(false), [])
    const connectionQualityPrct = Math.min(100, Math.max(0, Math.round(130 - connectionQuality)))
    const disconnected = connect !== "connect"
    const finish = (
        <>
            <p style={{color: "black", fontSize: "4vw"}}>Brawo ukończyłeś czytankę!</p>
            <p style={{color: "black", fontSize: "4vw"}}>Co chcesz zrobić teraz?</p>
            {showQuestions && sessionInfo.current &&
                <SessionEdit sessionId={sessionInfo.current.sessionId} onClose={() => navigate("/")}
                             onSave={() => navigate("/")}/>}
            <Button variant="outlined" onClick={() => navigate("/")}>Zakończ</Button>&nbsp;&nbsp;
            <Button variant="outlined" onClick={() => setShowQuestions(true)}>Wprowadź ocenę</Button>
        </>
    )
    return (
        <>
            <MicCapture microphone={microphone} output={micOutput} onVolume={onVolume}/>
            <SessionConfigDialog sessionConfig={{
                current: sessionConfig,
                onChange: (newS: SessionConfigVersioned) => ws?.sendMessage(SessionConfigUpdate(newS.version, newS.sessionConfig))
            }}
                                 microphone={{current: microphone, onChange: setMicrophone}} open={settingOpen}
                                 onClose={closeSettings}></SessionConfigDialog>
            <SessionRestartDialog open={connect === "failure" && !complete} onRestart={() => setConnect("connect")}
                                  onCancel={() => {
                                      setConnect("disconnect")
                                  }}></SessionRestartDialog>
            <AppLayout settingsAction={()=>setSettingOpen(true)} appBarComponents={
                // <AppBar>
                <Stack direction={"row"} sx={{columnGap: 0.5, flexGrow: 1, alignItems:"center"}}>
                    <Box sx={{flexGrow: 1}}/>

                    <ControlButton disabled={disconnected}
                        onClick={() => ws?.sendMessage(Messaging.PrevSentence)}><KeyboardDoubleArrowLeft/>{big && "Zdanie"}
                    </ControlButton>
                    <ControlButton disabled={disconnected}
                        onClick={() => ws?.sendMessage(Messaging.Rewind)}><KeyboardArrowLeft/>{big && "Słowo"}
                    </ControlButton>
                    <ToggleButton
                        value="ignored"
                        color="secondary"
                        selected={connect === "connect"}
                        onChange={() => setConnect((connect === "connect") ? "disconnect" : "connect")}
                    >
                        {(connect === "connect") ? <Pause/> : <PlayArrow/>}
                    </ToggleButton>
                    <ControlButton  disabled={disconnected}
                        onClick={() => ws?.sendMessage(Messaging.Forward)}><KeyboardArrowRight/>{big && "Słowo"}
                    </ControlButton>
                    <ControlButton  disabled={disconnected}
                        onClick={() => ws?.sendMessage(Messaging.NextSentence)}><KeyboardDoubleArrowRight/>{big && "Zdanie"}
                    </ControlButton>
                    <ControlButton disabled={disconnected} onClick={() => ws?.sendMessage(Messaging.Read)}><Hearing/></ControlButton>
                    <Box sx={{flexGrow: 1}}/>
                    <ControlButton onClick={() => navigate("/")}>
                        <Stop/>{big && "Zakończ"}
                    </ControlButton>
                    <Tooltip title={`Jakość połączenia: ${connectionQualityPrct}%`}>
                        {
                            connectionQualityPrct < 25 ? <SignalCellular1BarRoundedIcon color="primary"/> :
                                connectionQualityPrct < 50 ? <SignalCellular2BarRoundedIcon color="primary"/> :
                                    connectionQualityPrct < 75 ? <SignalCellular3BarRoundedIcon color="primary"/> :
                                        <SignalCellular4BarRoundedIcon color="primary"/>
                        }
                    </Tooltip>
                    <Tooltip title={`Głośność: ${Math.min(100, Math.round(volume / 10))}%`}>
                        {
                            volume < 300 ?
                                <SignalWifi1BarRoundedIcon color="primary" style={{transform: "rotate(90deg)"}}/> :
                                volume < 500 ? <SignalWifi2BarRoundedIcon color="primary"
                                                                          style={{transform: "rotate(90deg)"}}/> :
                                    volume < 1200 ? <SignalWifi3BarRoundedIcon color="primary"
                                                                               style={{transform: "rotate(90deg)"}}/> :
                                        <SignalWifi4BarRoundedIcon color="primary"
                                                                   style={{transform: "rotate(90deg)"}}/>
                        }
                    </Tooltip>
                </Stack>
            } footer={
                <Box sx={{
                    backgroundColor: "#27AE60",
                    borderBottomLeftRadius: 20,
                    borderBottomRightRadius: 20,
                    border: 'solid',
                    borderWidth: 5,
                    borderColor: "#219653",
                    alignItems: "flex-end"
                }}>
                    <Box sx={{width: '100%'}}>
                        {sessionConfig?.sessionConfig.timeLimit &&
                            <LinearProgress hidden={!sessionConfig.sessionConfig.timeLimit} color={"primary"}
                                            sx={{backgroundColor: "#27AE60"}}
                                            variant="determinate" value={sentenceProgress()}/>}
                    </Box>
                    <Toolbar>
                        <Medals done={reading?.done || 0} total={reading?.total || 0}/>
                        <Box sx={{flexGrow: 1}}/>
                        <Typography variant="h5" color={"white"}>{totalTime()}</Typography>
                    </Toolbar>
                </Box>
            }>
                <Box sx={{
                    height: "100%",
                    width: "100%",
                    borderRadius: 5,
                    backgroundColor: "white",
                    padding: 5,
                    fontFamily: 'Robo',
                    background: (connect !== "connect" && !complete) ? grey["400"] : undefined,
                    overflow: "scroll"
                }}>
                    {complete ? finish : <ReadingContent content={reading} ref={cRef}/>}
                </Box>
            </AppLayout>
        </>
    )
}

export default Reading;

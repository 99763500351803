import React, {useEffect, useState} from "react";
import {
    AppBar,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    List,
    ListItem,
    ListItemText,
    Slide,
    TextField,
    Toolbar
} from "@mui/material";
import * as jsonpatch from "fast-json-patch";
import {TraineeContext} from "./TraineeContext";
import {AuthenticationContext} from "../AuthenticationContext";
import {TransitionProps} from "@mui/material/transitions";
import {ReadingContent} from "../reading/ReadingContent";
import {ReadingUpdate} from "../reading/Messaging";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

interface SessionGrade {
    id: string,
    grade: number,
    notes: string,
}

interface SessionEditProps {
    sessionId: string
    onClose: () => void
    onSave: (session: SessionGrade) => void
}

interface Question {
    question: string
    answer: string
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export const SessionEdit: React.FC<SessionEditProps> = (props) => {
    const userContext = React.useContext(AuthenticationContext)
    const traineeContext = React.useContext(TraineeContext)


    const [showAnswers, setShowAnswers] = useState(false);
    const [session, setSession] = useState<SessionGrade>()
    const [originalSession, setOriginalSession] = useState<SessionGrade>()
    const [open, setOpen] = useState(true)
    const [showContent, setShowContnt] = useState(false)
    const [readingContent, setReadingContent] = useState<ReadingUpdate>()
    const save = () => {
        setOpen(false)
        if (session && originalSession) {
            const diff = jsonpatch.compare(originalSession, session)
            userContext.client().patch(`trainees/${traineeContext.trainee().id}/sessions/${props.sessionId}`, diff).then(() => props.onSave(session))
        }
    }

    const close = () => {
        setOpen(false)
        props.onClose()
    }
    const [questions, setQuestions] = useState<Question[]>([])
    const [correctAnswer, setCorrectAnswer] = useState<Set<Number>>(new Set())

    const updateSession = (field: keyof SessionGrade, value: any) => {
        setSession(session => session && ({
            ...session,
            [field]: value,
        }))
    }
    const handleToggle = (idx: number) => () => {
        setCorrectAnswer((prevState) => {
            if (!prevState.delete(idx)) prevState.add(idx)
            return new Set(prevState)
        })
        updateSession('grade', Math.round(correctAnswer.size * 100 / questions.length))
    }

    useEffect(() => {
        userContext.client().get<Question[]>(`sessions/${traineeContext.trainee().id}/${props.sessionId}/questions`).then((result) => {
            setQuestions(result.data)
            setCorrectAnswer(new Set())
        })
        userContext.client().get<SessionGrade>(`trainees/${traineeContext.trainee().id}/sessions/${props.sessionId}`).then((result) => {
            setSession(result.data)
            setOriginalSession(result.data)
        })
        userContext.client().get<ReadingUpdate>(`sessions/${traineeContext.trainee().id}/${props.sessionId}/content`).then((result) => {
            setReadingContent(result.data)
        })
    }, [userContext, traineeContext, props.sessionId])

    const cRef: React.RefObject<HTMLSpanElement|null> = React.createRef<HTMLSpanElement>()

    return (
        <Dialog open={open} onClose={close} fullWidth>
            <DialogTitle>Ocena rozumienia tekstu</DialogTitle>
            <DialogContent>
                <Dialog open={showContent} fullScreen TransitionComponent={Transition}>
                    <AppBar>
                        <Toolbar sx={{columnGap: 1}}>
                            <Button onClick={() => setShowContnt(false)}>Zamknij</Button>
                        </Toolbar>
                    </AppBar>
                    <Toolbar/>
                    <Box component="main" sx={{
                        flexGrow: 1,
                        padding: 5,
                        fontFamily: 'Helvetica, Arial, sans-serif'
                    }}>
                        <ReadingContent content={readingContent} ref={cRef}/>
                        <Toolbar/>
                    </Box>
                </Dialog>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 1,
                    gap: 2
                }}>
                    <List>
                        {questions.map((question, idx) =>
                            <ListItem
                                key={idx}
                                secondaryAction={<Checkbox edge="end" onChange={handleToggle(idx)} checked={correctAnswer.has(idx)}/>}
                            >
                                <ListItemText primary={question.question} secondary={showAnswers ? question.answer : "****"}/>
                            </ListItem>
                        )}
                    </List>
                    <FormControl fullWidth>
                        <TextField
                            disabled={!session}
                            label="Ocena"
                            value={session?.grade ?? ''}
                            onChange={(e: any) => updateSession('grade', e.target.value)}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            disabled={!session}
                            label="Notatki"
                            placeholder="miejsce na notatki..."
                            multiline
                            value={session?.notes ?? ''}
                            onChange={(e: any) => updateSession('notes', e.target.value)}
                        />
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                {showAnswers ?
                    <Button onClick={() => setShowAnswers(false)}><VisibilityOffOutlinedIcon/></Button> :
                    <Button onClick={() => setShowAnswers(true)}><VisibilityOutlinedIcon/></Button>}
                <Box sx={{flexGrow: 1}}/>
                <Button onClick={() => setShowContnt(true)}>Pokaż tekst</Button>
                <Button onClick={close}>Zamknij</Button>
                <Button onClick={save}>Zapisz</Button>
            </DialogActions>
        </Dialog>
    )
}
import React, {BaseSyntheticEvent} from "react";
import {AuthenticationContext} from "./AuthenticationContext";
import {
    AppBar,
    Box,
    Button,
    ButtonProps,
    Divider,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
    useMediaQuery
} from "@mui/material";
import {TraineeContext} from "./trainee/TraineeContext";
import {styled} from '@mui/material/styles';
import {Autorenew} from "@mui/icons-material";

interface Props {
    children: React.ReactNode,
    settingsAction?: () => void
}

const ProfileButton = styled(Button)<ButtonProps>(({theme}) =>
    ({
        color: theme.palette.text.primary,
        borderWidth: "0px",
        borderRadius: "100px",
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.primary.main,
        textTransform: "none",
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        minWidth: "unset",
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            '& .MuiButton-endIcon': {
                margin: 0
            }
        },

    })
)
const DefaultAppBar: React.FC<Props> = (props) => {
    const big = useMediaQuery((theme) => theme.breakpoints.up("sm"));
    const userContext = React.useContext(AuthenticationContext)
    const traineeContext = React.useContext(TraineeContext)
    const trainee = traineeContext.trainee()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    return (
        <AppBar position="sticky" elevation={0}
                sx={{borderBottom: 'solid', borderBottomWidth: 5, borderColor: "#A4B2C7"}}>
            <Toolbar sx={{gap: 1}}>
                <Menu
                    id="menu-appbar"
                    elevation={0}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    slotProps={{
                        paper: {
                            sx: {
                                border: "solid",
                                borderWidth: 2,
                                borderRadius: 5,
                                borderColor: "primary.main"
                            }
                        }
                    }}
                >
                    <Box display={"flex"} gap={2} paddingX={2} alignItems={"center"}>
                        <Typography color={"textDisabled"}>{trainee.name}</Typography>
                        <Button variant={"outlined"} size={"small"} sx={{minWidth: "20px", borderRadius: 5}}
                                onClick={() => traineeContext.close()}><Autorenew/></Button>
                    </Box>
                    <Divider variant={"middle"} sx={{borderColor: "primary.light", borderWidth: 1, marginY: 1}}/>
                    <MenuItem
                        onClick={props.settingsAction || (() => traineeContext.editProfile())}>Ustawienia</MenuItem>
                    <Divider variant={"middle"} sx={{borderColor: "primary.light", borderWidth: 1}}/>
                    <MenuItem onClick={() => userContext.logOut()}>Wyloguj</MenuItem>
                </Menu>
                {props.children}
                <ProfileButton variant={"outlined"} disableElevation
                               endIcon={<img src={"/profile-icon.svg"}/>}
                               onClick={(event: BaseSyntheticEvent) => setAnchorEl(event.currentTarget)}
                               className={"profile-button"}
                >
                    {big && trainee.name}
                </ProfileButton>
            </Toolbar>
        </AppBar>
    )
}

export default DefaultAppBar

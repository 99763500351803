import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {UserContextProvider} from "./AuthenticationContext";
import {plPL} from "@mui/material/locale";
import {createTheme,} from '@mui/material/styles';
import {ThemeProvider} from "@mui/material";
import {BrowserRouter, Navigate, Outlet, Route, Routes} from "react-router-dom";
import ReadingsList from "./reading/ReadingsList";
import {SessionsList} from "./trainee/SessionsList";
import Reading from "./reading/Reading";
import {TraineeContextProvider} from "./trainee/TraineeContext";
import {ChangePassword, ResetPassword, SignUp} from "./Login";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const myTheme = createTheme({
        colorSchemes: {
            light: {
                palette: {
                    primary: {
                        main: '#ef8f35',
                    },
                    secondary: {
                        main: '#FFF5D2',
                    },
                }
            }
        },
        components: {
            MuiTextField: {
                defaultProps: {
                    slotProps: {
                        input: {
                            disableUnderline: true,
                            sx: {borderRadius: 3}
                        }
                    }
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        '&.rounded': {borderRadius: "30px"},
                    }
                }
            },
            MuiAppBar: {
                styleOverrides: {
                    colorPrimary: {
                        backgroundColor: "white"
                    }
                }
            }
        }
    },
    plPL,);
export const ResetPasswordRoute = "/reset-passwd"
export const ChangePasswordRoute = "/change-passwd"
export const SignUpRoute = "/signup"
root.render(
    <BrowserRouter>
        <ThemeProvider theme={myTheme}>
            <Routes>
                <Route path={SignUpRoute} element={<SignUp/>}/>
                <Route path={ChangePasswordRoute} element={<ChangePassword/>}/>
                <Route path={ResetPasswordRoute} element={<ResetPassword/>}/>
                <Route path={""} element={<UserContextProvider>
                    <TraineeContextProvider>
                        <Outlet/>
                    </TraineeContextProvider>
                </UserContextProvider>}>
                    <Route index element={<Navigate replace to="readings"/>}/>
                    <Route path="readings" element={<ReadingsList/>}/>
                    <Route path="sessions" element={<SessionsList/>}/>
                    <Route path="reading/:library/:readingTitle" element={<Reading/>}/>
                </Route>
            </Routes>
        </ThemeProvider>
    </BrowserRouter>
);

import React, {useEffect} from "react";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {Login} from "./Login";
import {getAuth, onAuthStateChanged, signOut} from "firebase/auth";
import {initializeApp} from "firebase/app"
import {Registered} from "./Registered";

export type UserRole = "user" | "therapist" | "admin"

export interface ApplicationUser {
    readonly emailVerified: boolean
    readonly email: string
    readonly uid: string
    readonly roles: UserRole[]

    getIdToken(): Promise<string>

    hasAnyRole(...role: UserRole[]): Boolean
}

interface Client {
    get<T = any>(url: string): Promise<AxiosResponse<T>>

    patch<D, T = any>(url: string, body: D): Promise<AxiosResponse<T>>

    post<D, T = any>(url: string, body: D): Promise<AxiosResponse<T>>

    put<D, T = any>(url: string, body: D): Promise<AxiosResponse<T>>
}

const context = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BACKEND}/api/v1`

function execute<T, D = any>(user: ApplicationUser, config: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> {
    return user?.getIdToken().then(token =>
        axios.request<T>({
            headers: {
                Authorization: `Bearer ${token}`,
            },
            ...config,
        }))
}

const buildClient: (user: ApplicationUser) => Client = (user: ApplicationUser) => ({
    get<T = any>(url: string): Promise<AxiosResponse<T>> {
        return execute(user, {
            method: "GET",
            url: `${context}/${url}`
        })
    },
    patch<D, T = any>(url: string, body: D): Promise<AxiosResponse<T>> {
        return execute(user, {
            method: "PATCH",
            url: `${context}/${url}`,
            data: body
        })
    },
    post<D, T = any>(url: string, body: D): Promise<AxiosResponse<T>> {
        return execute(user, {
            method: "POST",
            url: `${context}/${url}`,
            data: body
        })
    },
    put<D, T = any>(url: string, body: D): Promise<AxiosResponse<T>> {
        return execute(user, {
            method: "PUT",
            url: `${context}/${url}`,
            data: body
        })
    }
})

interface Client {
    get<T = any>(url: string): Promise<AxiosResponse<T>>

    patch<D, T = any>(url: string, body: D): Promise<AxiosResponse<T>>

    post<D, T = any>(url: string, body: D): Promise<AxiosResponse<T>>

    put<D, T = any>(url: string, body: D): Promise<AxiosResponse<T>>
}

interface UserContext {
    user(): ApplicationUser

    client(): Client

    logOut(): void
}


const defaultUserContext: UserContext = {
    client(): Client {
        throw new Error("User not logged-in!")
    },
    user: () => {
        throw new Error("User not logged-in!")
    },
    logOut: () => {
        throw new Error("User not logged-in!")
    }
}

const AuthenticationContext = React.createContext<UserContext>(defaultUserContext);

interface Props {
    children: React.ReactNode;
}

const firebaseConfig = {
    apiKey: "AIzaSyCGyIkNY7Lsfv-lxnHVzXJ1Mh1jW-WQluE",
    authDomain: process.env.REACT_APP_AUTH_DOMAIN, //https://cloud.google.com/identity-platform/docs/web/redirect-best-practices#update-authdomain
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

//firebase funstions documentation is here: https://firebase.google.com/docs/reference/js/auth
const UserContextProvider: React.FC<Props> = ({children}) => {
    const [user, setUser] = React.useState<ApplicationUser | null>(null);
    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const idTokenResult = await user.getIdTokenResult(false)
                const roles: UserRole[] = idTokenResult.claims.roles ?? []
                if (user.email == null) throw new Error("Missing user email!")

                setUser(
                    {
                        email: user.email,
                        emailVerified: user.emailVerified,
                        uid: user.uid,
                        roles,
                        getIdToken: async () => {
                            return await user.getIdToken().catch((error: any) => {
                                console.log("Error getting a token, re-login required!", error)
                                throw new Error("Error getting a token, re-login required!")
                            })
                        },
                        hasAnyRole(...role: UserRole[]): Boolean {
                            return Boolean(roles.find(value => role.includes(value)))
                        },
                    }
                )
            } else {
                setUser(null)
            }
        });
    }, [])
    const userContext = (user: ApplicationUser) => ({
        user: () => user,
        logOut: () => signOut(auth),
        client: () => buildClient(user)
    })

    return user ? (
        <AuthenticationContext.Provider value={userContext(user)}>
            {user.roles.includes("user") ? children : <Registered/>}
        </AuthenticationContext.Provider>
    ) : (<Login setUser={setUser}/>)

}


export {AuthenticationContext, UserContextProvider};
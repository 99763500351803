import React from "react";
import {Box, Container, Link, Stack} from "@mui/material";
import DefaultAppBar from "../DefaultAppBar";

interface Props {
    centerVertically?: Boolean
    showAppBar?: Boolean
    appBarComponents?: React.ReactNode
    footer?: React.ReactNode
    settingsAction?: () => void
    children: React.ReactNode
}

const stdFooter = <Stack spacing={2} padding={2} sx={{
    backgroundColor: "#27AE60",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    border: 'solid',
    borderWidth: 5,
    borderColor: "#219653",
    alignItems: "flex-end"
}}>
    <Link href={"#"} underline={"hover"} color={"white"}>Pomoc</Link>
    <Link href={"#"} underline={"hover"} color={"white"}>Regulamin i Polityka Prywatności</Link>
</Stack>

const AppLayout: React.FC<Props> = (props) => {
    return (
        <Stack sx={{height:"100dvh"}}>
            {(props.showAppBar || props.appBarComponents) && <DefaultAppBar settingsAction={props.settingsAction}>
                {props.appBarComponents}
            </DefaultAppBar>}
            <Box id="background" sx={{
                backgroundImage: 'url("/background2.svg"),url("/background3.svg")',
                backgroundPosition: "bottom, top",
                backgroundSize: "100% auto",
                backgroundColor: "#e5ebf5ff",
                backgroundRepeat: "no-repeat",
                flexGrow: 1,
                overflow:"auto"
            }}>
                <Container sx={{
                    height: "100%", paddingTop: 2,
                    paddingBottom: 2,
                    display:"flex",
                    alignItems: props.centerVertically ? "center" : "top"
                }} maxWidth="xl">
                    {props.children}
                </Container>
            </Box>
            {props.footer || stdFooter}
        </Stack>)
}

export default AppLayout
